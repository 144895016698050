import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import Popup, { POPUP_CLOSER_CLASS } from "../Popup"
import "./styles.scss"

const LeavingSiteWarning = ({
  trigger,
  url,
  useSameWindow,
  doNotLogClick,
  className,
}) => {
  return (
    <Popup trigger={trigger}>
      <div className={`leaving-site-warning ${className || ""}`}>
        <h3>Please Note</h3>
        <p>
          You are now leaving BeVapeFree.org and will be re-directed. Upon
          leaving this site, you will be subject to the policies of the
          destination site and not BeVapeFree.org.
        </p>
        <p>
          <a
            className={classNames("button", POPUP_CLOSER_CLASS)}
            href={url}
            data-cep-ignore-click={doNotLogClick}
            target={useSameWindow ? "_self" : "_blank"}
            rel="noopener noreferrer"
          >
            Continue
          </a>
        </p>
      </div>
    </Popup>
  )
}

LeavingSiteWarning.propTypes = {
  url: PropTypes.string.isRequired,
  trigger: PropTypes.element.isRequired,
  useSameWindow: PropTypes.bool,
  doNotLogClick: PropTypes.bool,
}

LeavingSiteWarning.defaultProps = {
  useSameWindow: false,
}

export default LeavingSiteWarning
