import { Container } from "../Grid"
import Share from "../Share"
import Subscribe from "../Subscribe"
import Icon from "../Icon"
import Menu from "../Menu"
import HamburgerIcon from "../Hamburger"
import OutboundLink from "../OutboundLink"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"
import CVSLogo from "../../images/logo.svg"
import BeVapeFreeLogo from "../../images/theme/logo-be-vape-free.gif"
import "./styles.scss"

class Header extends React.Component {
  state = {
    menuOpen: false,
  }

  handleHamburgerClick = () => {
    this.setState(state => ({
      menuOpen: !state.menuOpen,
    }))
  }

  render() {
    const { data } = this.props
    const { menuOpen } = this.state

    return (
      <header className="header" id="#top">
        <Container>
          <div className="header__logos">
            <OutboundLink
              to="https://cvshealth.com/social-responsibility/our-giving/cvs-health-foundation"
              className="CVS-logo"
            >
              <span className="cvs-logo-text">Generously supported by</span>
              <img src={CVSLogo} alt={data.site.siteMetadata.partner} />
            </OutboundLink>
            <a href="/" className="BeVapeFree-logo">
              <img src={BeVapeFreeLogo} alt={data.site.siteMetadata.partner} />
            </a>
          </div>
          <div
            className={classNames(
              "header-links",
              menuOpen && "header-links--open"
            )}
          >
            <div className="utility__links">
              {/* <div className="header__signup">
                <Subscribe
                  trigger={
                    <button>
                      <Icon name="signup" inline />
                      Sign Up for Updates
                    </button>
                  }
                />
              </div> */}
              <div className="header__share">
                <Share />
              </div>
            </div>
            <nav className="primary__nav-container">
              <Menu />
            </nav>
          </div>
          <HamburgerIcon
            onClick={this.handleHamburgerClick}
            isActive={menuOpen}
          />
        </Container>
      </header>
    )
  }
}

Header.propTypes = {
  data: PropTypes.object.isRequired,
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            partner
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)
