import { Container, Row, Column } from "../Grid"
import Share from "../Share"
import Subscribe from "../Subscribe"
import OutboundLink from "../OutboundLink"
import React from "react"
import DiscoveryLogo from "../../images/global/img-de-logo-white.png"
import CATCHMyBreath from "../../images/theme/logo-catch-white.svg"
import CVSHealthFoundation from "../../images/theme/logo-cvs-health-foundation-white.svg"
import PrivacyPolicy from "../../docs/BeVapeFree.org_Privacy-Policy_2020.pdf"
import TermsOfUse from "../../docs/BeVapeFree.org-Terms-of-Use-2020.pdf"
import "./styles.scss"

const Footer = () => (
  <footer className="footer">
    <Container>
      <div className="footer__logos">
        <div className="footer__logos-sponsors">
          <OutboundLink
            to="https://www.discoveryeducation.com"
            className="discovery"
          >
            <img src={DiscoveryLogo} alt="Discovery Education" />
          </OutboundLink>
          <OutboundLink to="https://catch.org" className="catch">
            <img src={CATCHMyBreath} alt="CATCH My Breath" />
          </OutboundLink>
        </div>
        <OutboundLink
          to="https://cvshealth.com/social-responsibility/our-giving/cvs-health-foundation"
          className="cvshealth"
        >
          <img src={CVSHealthFoundation} alt="CVS Health Foundation" />
        </OutboundLink>
      </div>
      <div className="footer__links">
        {/* <div className="signup">
          <Subscribe
            trigger={
              <button className="global__button">Sign Up for Updates</button>
            }
          />
        </div> */}
        <div className="footer__share" style={{marginTop: "10px"}}>
          <Share />
        </div>
        <div className="footer__hastag">
          <a
            href="https://twitter.com/hashtag/bevapefree?f=tweets&amp;vertical=default&amp;src=hash"
            target="_blank"
            rel="noopener noreferrer"
          >
            #BeVapeFree
          </a>{" "}
        </div>
      </div>
    </Container>

    <div className="footer__copyright">
      <Container>
        <Row>
          <Column span={8}>
            <div className="footer__copyright-text">
              <p>
                &copy; {new Date().getFullYear()} The copyright of all materials
                on this site belong to the respective authors and/or creators of
                text and/or of image. <br />
                All rights are reserved. Reproduction in any form requires the
                written permission of the copyright owner(s). CATCH® is a
                registered trademark of The Regents of the University of
                California.
              </p>
            </div>
          </Column>
          <Column span={4}>
            <div className="footer__copyright-links">
              <a href={PrivacyPolicy} target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>{" "}
              |
              <a href={TermsOfUse} target="_blank" rel="noopener noreferrer">
                Terms of Use
              </a>{" "}
              |
              <a
                href="mailto:support@bevapefree.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Us
              </a>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  </footer>
)

Footer.defaultProps = {}
Footer.propTypes = {}
export default Footer
