import React, { useContext, useState } from "react"
import axios from "axios"
import moment from "moment"
import {
  Step,
  Form,
  TextInput,
  Checkbox,
  Select,
  SchoolLookup,
  Button,
} from "./"
import { LookupStatus } from "./SchoolLookup"
import EducatorContext from "../../context/EducatorContext"
import PrivacyPolicy from "../../docs/BeVapeFree.org_Privacy-Policy_2020.pdf"
import CoppaContext from "../../context/CoppaContext"

const FormStatus = {
  NOT_STARTED: "NOT_STARTED",
  STARTED: "STARTED",
  SUBMITTED: "SUBMITTED",
  COMPLETE: "COMPLETE",
}

const initialState = {
  formStep: 1,
  formFields: {
    1: {
      email: {
        value: "",
        isValid: false,
      },
      birthdayDay: {
        value: "",
        isValid: false,
      },
      birthdayMonth: {
        value: "",
        isValid: false,
      },
      birthdayYear: {
        value: "",
        isValid: false,
      },
      "Is an educator": {
        value: true,
        isValid: true,
      },
      "Student reach": {
        value: "",
        isValid: false,
      },
      zip: {
        value: "",
        isValid: false,
      },
      "Receive email from CATCH": {
        value: true,
        isValid: true,
      },
    },
    2: {
      school: {
        value: {},
        isValid: false,
      },
    },
  },
  formComplete: false,
}

const COPPA_MIN_AGE = 13

const DLBAccess = ({ id, onSubmit }) => {
  const [formStatus, setFormStatus] = useState(FormStatus.NOT_STARTED)
  const [schoolLookupStatus, setSchoolLookupStatus] = useState(
    LookupStatus.SELECT_MODE
  )
  const [state, setState] = useState(initialState)
  const educatorValue = useContext(EducatorContext)
  const coppaContext = useContext(CoppaContext)

  const validateField = (field, value) => {
    let validated = false
    switch (field) {
      case "email":
        validated = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
        break
      case "zip":
        validated = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
        break
      case "school":
        validated = Object.keys(value).every(key => value[key].valid)
        break
      default:
        validated = value !== null && value !== ""
        break
    }

    return validated
  }

  const handleFormChange = (field, value) => {
    if (formStatus === FormStatus.NOT_STARTED) {
      setFormStatus(FormStatus.STARTED)
    }
    setState(state => ({
      ...state,
      formFields: {
        ...state.formFields,
        [state.formStep]: {
          ...state.formFields[state.formStep],
          [field]: {
            value,
            isValid: validateField(field, value),
          },
        },
      },
    }))
  }

  const fieldsAreValid = () => {
    const { formStep, formFields } = state
    const fields = { ...formFields[formStep] }
    let invalid = true
    switch (formStep) {
      case 1:
        invalid =
          !fields.email.isValid ||
          !fields.zip.isValid ||
          (fields["Is an educator"].value && !fields["Student reach"].isValid)
        break
      case 2:
        invalid = !fields.school.isValid
        break
      default:
        invalid = false
        break
    }
    return !invalid
  }

  const birthdayIsOkay = () => {
    const { formStep, formFields } = state
    if (formStep === 1) {
      const birthday = moment(
        `${formFields[1].birthdayYear.value}/${formFields[1].birthdayMonth.value}/${formFields[1].birthdayDay.value}`
      )
      const age = moment().diff(birthday, "years")
      const birthdayFieldsFilled =
        formFields[1].birthdayDay.value &&
        formFields[1].birthdayMonth.value &&
        formFields[1].birthdayYear.value
      if (!birthdayFieldsFilled || !birthday.isValid()) {
        return false
      } else if (age < COPPA_MIN_AGE) {
        coppaContext.setCoppaRestricted(true)
        return false
      }
    }
    return true
  }

  const handleFormSubmit = () => {
    if (
      formStatus === FormStatus.NOT_STARTED ||
      formStatus === FormStatus.STARTED
    ) {
      setFormStatus(FormStatus.SUBMITTED)
    }
    const { formStep } = state
    
    if (birthdayIsOkay() && fieldsAreValid()) {
      switch (formStep) {
        case 1:
          setState(state => ({
            ...state,
            formStep: state.formStep + 1,
          }))
          break
        case 2:
          postForm()
          onSubmit()
          break
        default:
          break
      }
    }
  }

  const postForm = () => {
    const { formFields } = state
    const schoolValue = {}
    Object.keys(formFields[2].school.value).forEach(
      key => (schoolValue[key] = formFields[2].school.value[key].value)
    )
    const fields = {
      type: "access",
      email: formFields[1].email.value,
      data: {
        Form: id,
        "Is an educator": formFields[1]["Is an educator"].value,
        "Student reach": formFields[1]["Student reach"].value,
        "Receive email from CATCH":
          formFields[1]["Receive email from CATCH"].value,
        ...schoolValue,
      },
    }
    educatorValue.setSubmittedFields(fields)
    axios.post(`/api/form-entry`, fields, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    setState({ ...state, formComplete: true })
    setFormStatus(FormStatus.COMPLETE)
  }

  const validationWarningEnabled = () => {
    return (
      formStatus === FormStatus.SUBMITTED &&
      (formStep === 1 || schoolLookupStatus === LookupStatus.MANUAL)
    )
  }

  const { formStep, formFields } = state
  return (
    <Form onSubmit={handleFormSubmit} classes={["download-access-form"]}>
      <Step current={formStep === 1}>
        <TextInput
          type="email"
          label="Email"
          name="email"
          placeholder="email@address.com"
          value={formFields[1].email.value}
          labelHidden
          valid={
            validationWarningEnabled() ? formFields[1].email.isValid : true
          }
          onChange={handleFormChange}
        />
        <p className="birthday-fields__label">Date of Birth</p>
        <div className="subscribe__birthday-fields">
          <Select
            name="birthdayMonth"
            label="Birthday Month"
            placeholder="Month"
            options={{
              1: "January",
              2: "February",
              3: "March",
              4: "April",
              5: "May",
              6: "June",
              7: "July",
              8: "August",
              9: "September",
              10: "October",
              11: "November",
              12: "December",
            }}
            labelHidden
            valid={
              validationWarningEnabled()
                ? formFields[1].birthdayMonth.isValid
                : true
            }
            onChange={handleFormChange}
          />
          <Select
            name="birthdayDay"
            label="Birthday Day"
            placeholder="Day"
            options={[...Array(31).keys()].map(number => 1 + number)}
            labelHidden
            valid={
              validationWarningEnabled()
                ? formFields[1].birthdayDay.isValid
                : true
            }
            onChange={handleFormChange}
          />
          <Select
            name="birthdayYear"
            label="Birthday Year"
            placeholder="Year"
            options={[...Array(85).keys()].map(
              number => new Date().getFullYear() - number
            )}
            labelHidden
            valid={
              validationWarningEnabled()
                ? formFields[1].birthdayYear.isValid
                : true
            }
            onChange={handleFormChange}
          />
        </div>
        <Checkbox
          name="Is an educator"
          label="I am an educator and plan to use these resources with students."
          checked={formFields[1]["Is an educator"].value}
          onChange={handleFormChange}
        />

        {formFields[1]["Is an educator"].value && (
          <Select
            name="Student reach"
            label="Number of students you'll reach"
            placeholder="Number of students you'll reach"
            options={[
              "10—20 students",
              "20—30 students",
              "30—40 students",
              "40—50 students",
              "50—60 students",
              "60—70 students",
              "70—80 students",
              "80—90 students",
              "90—100 students",
              "100+ students",
            ]}
            value={formFields[1]["Student reach"].value}
            valid={
              validationWarningEnabled()
                ? formFields[1]["Student reach"].isValid
                : true
            }
            labelHidden
            onChange={handleFormChange}
          />
        )}

        <TextInput
          type="text"
          label={`${
            formFields[1]["Is an educator"].value ? "School" : "Home"
          } Zip Code`}
          name="zip"
          placeholder={`${
            formFields[1]["Is an educator"].value ? "School" : "Home"
          } Zip Code`}
          value={formFields[1].zip.value}
          valid={validationWarningEnabled() ? formFields[1].zip.isValid : true}
          labelHidden
          onChange={handleFormChange}
        />
        <Checkbox
          name="Receive email from CATCH"
          label={
            <span>
              I would like to receive information about the other health
              education topics from CATCH in accordance with the following{" "}
              <a target="_blank" href={PrivacyPolicy}>
                Privacy Policy
              </a>
              .
            </span>
          }
          checked={formFields[1]["Receive email from CATCH"].value}
          labelHidden
          onChange={handleFormChange}
        />
      </Step>
      <Step current={formStep === 2}>
        <SchoolLookup
          zip={parseInt(formFields[1].zip.value)}
          value={formFields[2].school.value}
          onChange={handleFormChange}
          lookupStatusListener={setSchoolLookupStatus}
        />
      </Step>

      <Button>{formStep === 1 ? "Continue" : "Submit"}</Button>
    </Form>
  )
}

export default DLBAccess
