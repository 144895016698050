/* eslint-env browser */
import React from "react"
import PropTypes from "prop-types"
import Header from "../Header"
import Footer from "../Footer"
import EducatorContext from "../../context/EducatorContext"
import ParentContext from "../../context/ParentContext"
import useParentContextValue from "../../context/useParentContextValue"
import useEducatorContextValue from "../../context/useEducatorContextValue"
import CoppaContext from "../../context/CoppaContext"
import useCoppaContextValue from "../../context/useCoppaContextValue"

const Layout = ({ children }) => {
  const educatorContextValue = useEducatorContextValue()
  const parentContextValue = useParentContextValue()
  const coppaContextValue = useCoppaContextValue()

  return (
    <>
      <CoppaContext.Provider value={coppaContextValue}>
        <EducatorContext.Provider value={educatorContextValue}>
          <ParentContext.Provider value={parentContextValue}>
            <Header />
            <main>{children}</main>
            <Footer />
          </ParentContext.Provider>
        </EducatorContext.Provider>
      </CoppaContext.Provider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
