import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import Select from "./Select"
import TextInput from "./TextInput"
import { validateNonEmptyString, validateZipCode } from "./validators"

const states = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
}

const LookupStatus = {
  NOT_STARTED: "NOT_STARTED",
  STARTED: "STARTED",
  MANUAL_MODE: "MANUAL_MODE",
  API_ERROR: "API_ERROR",
  COMPLETE: "COMPLETE",
}

const SCHOOL_LABELS = {
  name: "School/Homeschool Name",
  address: "School/Homeschool Address",
  city: "School/Homeschool City",
  state: "School/Homeschool State",
  zip: "School/Homeschool Postal Code",
  district: "School/Homeschool District",
}

const ORG_LABELS = {
  name: "Organization Name",
  address: "Organization Address",
  city: "Organization City",
  state: "Organization State",
  zip: "Organization Postal Code",
}

const initialFields = {
  inst: {
    value: "",
    valid: false,
  },
  mstreet: {
    value: "",
    valid: false,
  },
  mcity: {
    value: "",
    valid: false,
  },
  mstate: {
    value: "",
    valid: false,
  },
  mzipcode: {
    value: "",
    valid: false,
  },
  district: {
    value: "",
    valid: false,
  },
}

const orgInitialFields = {
  inst: {
    value: "",
    valid: false,
  },
  mstreet: {
    value: "",
    valid: false,
  },
  mcity: {
    value: "",
    valid: false,
  },
  mstate: {
    value: "",
    valid: false,
  },
  mzipcode: {
    value: "",
    valid: false,
  },
}

class SchoolLookup extends React.Component {
  constructor(props) {
    super(props)

    this.labels = props.useOrgLabels ? ORG_LABELS : SCHOOL_LABELS

    this.state = {
      schools: [],
      manualEntry: false,
      fields: props.useOrgLabels ? orgInitialFields : initialFields,
      lookupStatus: LookupStatus.NOT_STARTED,
    }
  }

  componentDidMount() {
    this.getSchools()
  }

  updateLookupStatus(status) {
    const { lookupStatusListener } = this.props
    this.setState({
      lookupStatus: status,
    })
    lookupStatusListener && lookupStatusListener(status)
  }

  getSchools = () => {
    const { zip } = this.props

    // start spinner

    axios
      .get(`https://cepdata.discoveryeducation.com/api/v2/schools`, {
        params: {
          zip,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(schools => {
        this.setState({
          schools: schools.data.data,
        })
        this.updateLookupStatus(LookupStatus.COMPLETE)
      })
      .catch(error => {
        // handle errors
        this.updateLookupStatus(LookupStatus.API_ERROR)
      })
      .finally(() => {
        //end spinner
      })
  }

  getSchoolObject = school => ({
    "School ID": {
      value: school.pid || "",
      valid: true,
    },
    "School name": {
      value: school.inst.value !== undefined ? school.inst.value : school.inst,
      valid: school.inst.valid !== undefined ? school.inst.valid : true,
    },
    "School street": {
      value:
        school.mstreet.value !== undefined
          ? school.mstreet.value
          : school.mstreet,
      valid: school.mstreet.valid !== undefined ? school.mstreet.valid : true,
    },
    "School city": {
      value:
        school.mcity.value !== undefined ? school.mcity.value : school.mcity,
      valid: school.mcity.valid !== undefined ? school.mcity.valid : true,
    },
    "School state": {
      value:
        school.mstate.value !== undefined ? school.mstate.value : school.mstate,
      valid: school.mstate.valid !== undefined ? school.mstate.valid : true,
    },
    "School zip": {
      value:
        school.mzipcode.value !== undefined
          ? school.mzipcode.value
          : school.mzipcode,
      valid: school.mzipcode.valid !== undefined ? school.mzipcode.valid : true,
    },
    "School district": {
      value:
        school.district && school.district.value !== undefined
          ? school.district.value
          : school.district || "",
      valid:
        school.district && school.district.valid !== undefined
          ? school.district.valid
          : true,
    },
  })

  getSchoolDetails = schoolName => {
    const { schools } = this.state
    for (let school of schools) {
      if (school.inst === schoolName) {
        return this.getSchoolObject(school)
      }
    }
    // If the user selects the placeholder again
    // then return the default empty fields.
    return this.props.useOrgLabels ? orgInitialFields : initialFields
  }

  handleSchoolChange = (name, value) => {
    const { onChange } = this.props
    const school = this.getSchoolDetails(value)
    this.updateLookupStatus(LookupStatus.STARTED)
    this.setState(
      {
        fields: school,
      },
      () => {
        onChange && onChange("school", this.state.fields)
      }
    )
  }

  validateField = (name, value) => {
    let valid = false
    switch (name) {
      case "mzipcode":
        valid = validateZipCode(value)
        break
      default:
        valid = validateNonEmptyString(value)
    }
    return valid
  }

  handleManualInputChange = (name, value) => {
    const { onChange } = this.props
    let valid = this.validateField(name, value)

    this.setState(
      state => ({
        fields: {
          ...state.fields,
          [name]: {
            value,
            valid,
          },
        },
      }),
      () => {
        const { fields } = this.state
        onChange && onChange("school", this.getSchoolObject(fields))
      }
    )
  }

  handleClick = () => {
    const { onChange } = this.props
    this.setState(
      {
        manualEntry: true,
        fields: this.props.useOrgLabels ? orgInitialFields : initialFields,
      },
      () => {
        const { fields } = this.state
        onChange && onChange("school", this.getSchoolObject(fields))
      }
    )
    this.updateLookupStatus(LookupStatus.MANUAL_MODE)
  }

  renderSelect = () => {
    const { schools, fields } = this.state
    return (
      <>
        <Select
          name="school"
          label="Select Your School"
          placeholder="Select Your School"
          options={schools.map(school => school.inst)}
          value={fields.inst}
          valid={
            this.props.validationWarningEnabled
              ? Object.keys(fields).every(key => fields[key].valid)
              : true
          }
          labelHidden
          onChange={this.handleSchoolChange}
        />
        <p>
          Not seeing your school? Home schooled?
          <br />
          <button
            className="form__school-lookup-button"
            type="button"
            onClick={this.handleClick}
          >
            Enter your information ›
          </button>
        </p>
      </>
    )
  }

  renderEntryForm = () => {
    const { fields } = this.state
    return (
      <>
        <TextInput
          type="text"
          label={this.labels.name}
          name="inst"
          placeholder={this.labels.name}
          value={fields.inst.value}
          labelHidden
          onChange={this.handleManualInputChange}
          valid={this.props.validationWarningEnabled && fields.inst.valid}
        />
        <TextInput
          type="text"
          label={this.labels.address}
          name="mstreet"
          placeholder={this.labels.address}
          value={fields.mstreet.value}
          labelHidden
          onChange={this.handleManualInputChange}
          valid={this.props.validationWarningEnabled && fields.mstreet.valid}
        />
        <TextInput
          type="text"
          label={this.labels.city}
          name="mcity"
          placeholder={this.labels.city}
          value={fields.mcity.value}
          labelHidden
          onChange={this.handleManualInputChange}
          valid={this.props.validationWarningEnabled && fields.mcity.valid}
        />
        <Select
          name="mstate"
          label={this.labels.state}
          placeholder={this.labels.state}
          options={states}
          value={fields.mstate.value}
          labelHidden
          onChange={this.handleManualInputChange}
          valid={this.props.validationWarningEnabled && fields.mstate.valid}
        />
        <TextInput
          type="text"
          label={this.labels.zip}
          name="mzipcode"
          placeholder={this.labels.zip}
          value={fields.mzipcode.value}
          labelHidden
          onChange={this.handleManualInputChange}
          valid={this.props.validationWarningEnabled && fields.mzipcode.valid}
        />
        {!this.props.useOrgLabels && (
          <TextInput
            type="text"
            label={this.labels.district}
            name="district"
            placeholder={this.labels.district}
            value={fields.district.value}
            labelHidden
            onChange={this.handleManualInputChange}
            valid={this.props.validationWarningEnabled && fields.district.valid}
          />
        )}
      </>
    )
  }

  render() {
    return this.state.manualEntry ? this.renderEntryForm() : this.renderSelect()
  }
}

SchoolLookup.propTypes = {
  zip: PropTypes.number.isRequired,
  validationWarningEnabled: PropTypes.bool,
  useOrgLabels: PropTypes.bool,
}

SchoolLookup.defaultProps = {
  validationWarningEnabled: true,
  useOrgLabels: false,
}

export default SchoolLookup

export { LookupStatus }
