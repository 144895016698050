import { Link } from "gatsby"
import React from "react"
import "./styles.scss"

const Menu = () => (
  <nav>
    <ul className="primary__nav">
      <li>
        <Link activeClassName="active" to="/educators/">
          Educators
        </Link>
      </li>
      <li>
        <Link activeClassName="active" to="/students/">
          Students
        </Link>
      </li>
      <li>
        <Link activeClassName="active" to="/parents-and-community/">
          Parents & Community
        </Link>
      </li>
      <li>
        <Link activeClassName="active" to="/virtual-field-trip/">
          Virtual Field Trip
        </Link>
        <ul>
        <li>
            <Link activeClassName="active" to="/heroes-vft/">
              Heroes
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/just-the-facts-vft/">
              Just the Facts
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/clearing-the-air-vft/">
              Clearing the Air
            </Link>
          </li>
        </ul>
      </li>
      <li>
        <Link activeClassName="active" to="/about/">
          About
        </Link>
      </li>
    </ul>
  </nav>
)

Menu.propTypes = {}

export default Menu
