import React, { useEffect, useState } from "react"
import Icon from "../Icon"
import PropTypes from "prop-types"
import LeavingSiteWarning from "../LeavingSiteWarning"
import useHostname from "../../hooks/useHostname"

const OutboundLink = ({
  children,
  to,
  withIcon,
  className,
  doNotLogClick,
  disableWarning,
}) => {
  const siteDomain = useHostname()
  const [isExternalDomain, setIsExternalDomain] = useState(false)

  useEffect(() => {
    const domainWithoutScheme = siteDomain.replace(/^https?:\/\//i, "")
    const linkWithouthScheme = to
      .replace(/^\//, "")
      .replace(/^https?:\/\//i, "")
    if (
      to.indexOf("http") === 0 &&
      linkWithouthScheme.indexOf(domainWithoutScheme) !== 0
    ) {
      // The target domain does NOT match current domain
      setIsExternalDomain(true)
    }
  }, [to, siteDomain])

  const renderLink = () => {
    const link = (
      <a
        href={to}
        target="_blank"
        data-cep-ignore-click={doNotLogClick}
        rel="noopener noreferrer"
        className={className}
      >
        {withIcon && <Icon name="externallink" inline />}
        {children}
      </a>
    )
    if (disableWarning || !isExternalDomain) {
      return link
    } else {
      return (
        <LeavingSiteWarning
          url={to}
          doNotLogClick={doNotLogClick}
          trigger={
            <a className={className}>
              {withIcon && <Icon name="externallink" inline />}
              {children}
            </a>
          }
        />
      )
    }
  }

  return renderLink()
}

OutboundLink.defaultProps = {
  withIcon: false,
  className: null,
}

OutboundLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  withIcon: PropTypes.bool,
  className: PropTypes.string,
  disableWarning: PropTypes.bool,
  doNotLogClick: PropTypes.bool,
}

export default OutboundLink
