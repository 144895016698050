import PropTypes from "prop-types"
import React from "react"
import Icon from "../Icon"
import { useStaticQuery, graphql } from "gatsby"
import "./styles.scss"

const Share = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            twitterText
            emailSubject
            emailBody
          }
        }
      }
    `
  )

  return (
    <div className="share">
      Share:
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${site.siteMetadata.siteUrl}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon name="facebook" />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?text=${site.siteMetadata.twitterText}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon name="twitter" />
      </a>
      <a
        href={`mailto:?subject=${site.siteMetadata.emailSubject}&body=${site.siteMetadata.emailBody}`}
      >
        <Icon name="email" />
      </a>
    </div>
  )
}

Share.defaultProps = {
  parnter: null,
  menuLinks: null,
}

Share.propTypes = {
  partner: PropTypes.string,
  menuLinks: PropTypes.string,
}

export default Share
