import React from "react"
import Icon from "../Icon"
import Image from "../Image"
import Modal from "./Modal"
import Video from "../Video"

const ImageVideoModal = ({ imgPlaceholder, guidSrc, src='' }) => {
  return (
    <div className="video">
      <Modal
        trigger={
          <button>
            <Icon name="play" inline />
            <Image filename={imgPlaceholder} />
          </button>
        }
        video
      >
        {
        src && <Video src={src} />
        }
        {guidSrc && <Video guidSrc={guidSrc} />}
      </Modal>
    </div>
  )
}

export default ImageVideoModal
