import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { CSSTransition } from "react-transition-group"
import classNames from "classnames"
import Icon from "../Icon"
import "./styles.scss"

class Modal extends React.Component {
  constructor() {
    super()

    this.state = {
      open: false,
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("modal-open")
    document.body.style.paddingRight = ""
  }

  getScrollbarWidth = () => {
    const scrollDiv = document.createElement("div")
    scrollDiv.className = "modal__scrollbar-measure"
    document.body.appendChild(scrollDiv)
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
    document.body.removeChild(scrollDiv)
    return scrollbarWidth
  }

  handleTriggerClick = event => {
    const { trigger } = this.props
    this.setState({ open: true })
    if (trigger.props.onClick) {
      trigger.props.onClick(event)
    }
    document.body.classList.add("modal-open")
    const scrollbarWidth = this.getScrollbarWidth()
    document.body.style.paddingRight = `${scrollbarWidth}px`
  }

  handleContainerClick = event => {
    if (event.target === event.currentTarget) {
      this.handleCloseClick()
    }
  }

  handleCloseClick = () => {
    this.setState({
      open: false,
    })
    document.body.classList.remove("modal-open")
    document.body.style.paddingRight = ""
  }

  renderModal = () => {
    const { children, video } = this.props
    return (
      <div className="modal">
        <div className="modal__overlay" />
        <div
          className="modal__container"
          role="dialog"
          aria-modal="true"
          tabIndex="-1"
          ref={m => {
            this.modal = m
          }}
          onClick={this.handleContainerClick}
        >
          <div
            className={classNames(
              "modal__content",
              video && "modal__content--with-video"
            )}
          >
            {" "}
            <button
              onClick={this.handleCloseClick}
              className="modal__close-btn"
            >
              <Icon name="close" />
            </button>
            {children}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { open } = this.state
    const { trigger } = this.props

    return (
      <>
        {React.cloneElement(trigger, { onClick: this.handleTriggerClick })}
        <CSSTransition
          in={open}
          timeout={400}
          classNames="modal-"
          unmountOnExit
        >
          <>
            {typeof document !== "undefined" &&
              ReactDOM.createPortal(this.renderModal(), document.body)}
          </>
        </CSSTransition>
      </>
    )
  }
}

Modal.defaultProps = {
  video: false,
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  video: PropTypes.bool,
}

export default Modal
