import { useEffect, useState } from "react"

const PARENT_KEY = "parentFormProcessed"

/**
 * Context value for the Parent Access Form.
 */
const useParentContextValue = () => {
  const [parentFormProcessed, setParentFormProcessed] = useState(false)
  const [parentContextValue, setParentContextValue] = useState({
    parentFormProcessed: false,
    markParentFormAsProcessed: () => {},
  })

  // componentDidUpdate
  useEffect(() => {
    setParentFormProcessed(sessionStorage.getItem(PARENT_KEY) === "true")
  }, [])

  useEffect(() => {
    setParentContextValue({
      parentFormProcessed,
      markParentFormAsProcessed: () => {
        sessionStorage.setItem(PARENT_KEY, true)
        setParentFormProcessed(true)
      },
    })
  }, [parentFormProcessed])

  return parentContextValue
}

export default useParentContextValue
