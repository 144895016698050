import { useStaticQuery, graphql } from "gatsby"

const useImages = () => {
  const data = useStaticQuery(graphql`
    query ImageQuery {
      images: allFile(
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" } }
      ) {
        edges {
          node {
            relativePath
            name
            extension
            childImageSharp {
              sizes(maxWidth: 1440, quality: 90) {
                ...GatsbyImageSharpSizes_noBase64
              }
            }
          }
        }
      }
    }
  `)

  /**
   * Return the path of the *first* image that exactly matches by source filename.
   */
  const getFinalPathByFilename = filename => {
    const matchingEdge = data.images.edges.find(edge => {
      return filename === `${edge.node.name}.${edge.node.extension}`
    })
    return matchingEdge ? matchingEdge.node.childImageSharp.sizes.src : null
  }

  /**
   * Get the final path of the provided source image path.
   * @param {string} filepath The path relative to the 'images' folder.
   */
  const getFinalPath = filepath => {
    const matchingEdge = data.images.edges.find(edge => {
      // Replace used to remove leading slash if provided...
      return filepath.replace(/^\//, "") === edge.node.relativePath
    })
    return matchingEdge ? matchingEdge.node.childImageSharp.sizes.src : null
  }

  return {
    images: data.images,
    getFinalPathByFilename,
    getFinalPath,
  }
}

export default useImages
