import React from "react"

const EducatorContext = React.createContext({
  highSchoolProcessed: false,
  markHighSchoolAsProcessed: () => { },
  middleSchoolProcessed: false,
  markMiddleSchoolAsProcessed: () => { },
  elementarySchoolProcessed: false,
  markElementarySchoolAsProcessed: () => { },
  submittedFields: {},
  setSubmittedFields: () => { },
})

export default EducatorContext
