import React, { useEffect, useState, useCallback } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import classNames from "classnames"
import "./styles.scss"

const CONTAINER_ID = "popup-container"
const POPUP_CLASS = "popup-component"
const TRIGGER_CLASS = "popup-component-trigger"
const POPUP_CLOSER_CLASS = "popup-component-closer"

const ancestorIsPopupOrTrigger = child => {
  let result = false
  let parent = child
  while (
    (parent = parent.parentElement) &&
    !parent.classList.contains(POPUP_CLASS) &&
    !parent.classList.contains(TRIGGER_CLASS)
  );
  if (parent !== null) {
    result = true
  }
  return result
}

const ancestorIsPopupCloser = child => {
  let result = false
  let parent = child
  while (
    (parent = parent.parentElement) &&
    !parent.classList.contains(POPUP_CLOSER_CLASS)
  );
  if (parent !== null) {
    result = true
  }
  return result
}

/**
 * 1] This component requires a trigger that has a root node
 * of an actual html element, or class, but not a function component.
 * If you want to use your own trigger function component
 * wrap it in a native <div> or <span> - whichever is more appropriate.
 *
 * e.g.
 * <Popup trigger={<div><MyTriggerComponent /></div>} />Popup text</Popup>
 *
 * 2] You can flag a child element that will close the popup if clicked.
 * Mark the child with the 'POPUP_CLOSER_CLASS'. If you want this behavior,
 * the click MUST be allowed to bubble up the DOM so the popop container
 * can see it. If you do:
 * 'event.preventDefault()' then the event won't bubble and the popup will stay closed.
 */
const Popup = ({ children, yOffset, trigger }) => {
  const [show, setShow] = useState(false)
  const [popupNode, setPopupNode] = useState(null)
  const [triggerNode, setTriggerNode] = useState(null)
  const [popupContainer, setPopupContainer] = useState(null)

  // Configure trigger
  const clickHandler = () => {
    setShow(true)
  }

  // Add popup container if it doesn't exist
  useEffect(() => {
    let container = document.getElementById(CONTAINER_ID)
    if (!container) {
      container = document.createElement("div")
      container.setAttribute("id", CONTAINER_ID)
      document.body.appendChild(container)
    }
    setPopupContainer(container)
  }, [])

  // Configure body click
  useEffect(() => {
    if (!popupNode) return

    const bodyListener = event => {
      const { target } = event
      // Ignore clicks from popups.
      if (target.className.indexOf(POPUP_CLASS) > -1) return
      if (target.className.indexOf(TRIGGER_CLASS) > -1) return
      if (ancestorIsPopupOrTrigger(target)) return

      // If we are here then the click was not from a popup or trigger.
      setShow(false)
    }
    document.body.addEventListener("click", bodyListener)
    return () => {
      document.body.removeEventListener("click", bodyListener)
    }
  }, [popupNode])

  const popupRefCallback = useCallback(node => {
    setPopupNode(node)
  }, [])

  const triggerRefCallback = useCallback(node => {
    setTriggerNode(node)
  }, [])

  const popupContainerClickHandler = event => {
    const { target } = event
    if (
      target.classList.contains(POPUP_CLOSER_CLASS) ||
      ancestorIsPopupCloser(target)
    ) {
      setShow(false)
    }
  }

  const calculateStyle = () => {
    if (triggerNode) {
      const y =
        triggerNode.getBoundingClientRect()["top"] +
        window.pageYOffset +
        yOffset
      return { top: y }
    }
    if (window) {
      const y = window.scrollY + window.innerHeight / 2
      return { top: y }
    }
    return {}
  }

  const renderPopup = () => {
    if (show) {
      return (
        <div
          ref={popupRefCallback}
          className={POPUP_CLASS}
          style={calculateStyle()}
          onClick={popupContainerClickHandler}
        >
          <button
            aria-label="Popup close button"
            className="popup-close-button"
            onClick={() => setShow(false)}
          />
          {children}
        </div>
      )
    }
    return null
  }

  return (
    <>
      {React.cloneElement(trigger, {
        className: classNames(trigger.props.className, TRIGGER_CLASS),
        onClick: clickHandler,
        ref: triggerRefCallback,
      })}
      {show &&
        popupContainer &&
        ReactDOM.createPortal(renderPopup(), popupContainer)}
    </>
  )
}

Popup.propTypes = {
  yOffset: PropTypes.number,
}

Popup.defaultProps = {
  yOffset: -30,
}

export default Popup

export { POPUP_CLOSER_CLASS }
