import { useEffect, useState } from "react"
import Cookies from "js-cookie"

const COPPA_STATUS_KEY = "COPPA_STATUS_KEY"

const useCoppaContextValue = () => {
  const [restricted, setRestricted] = useState(false)
  const [coppaContextValue, setCoppaContextValue] = useState({
    coppaRestricted: false,
    setCoppaRestricted: () => {},
  })

  useEffect(() => {
    setRestricted(Cookies.get(COPPA_STATUS_KEY) === "true")
  }, [])

  useEffect(() => {
    setCoppaContextValue({
      coppaRestricted: restricted,
      setCoppaRestricted: value => {
        Cookies.set(COPPA_STATUS_KEY, `${!!value}`, { expires: 7 })
        setRestricted(!!value)
      },
    })
  }, [restricted])

  return coppaContextValue
}

export default useCoppaContextValue
