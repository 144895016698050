import PropTypes from "prop-types"
import classNames from 'classnames';
import React from "react"
import "./styles.scss"

const Column = ({ span, children }) => {
  const getClassNames = () => classNames(
    'column',
    `is-${span}`
  )

  return (
    <div className={getClassNames()}>
      {children}
    </div>
  )
}

Column.defaultProps = {
  span: 12
}

Column.propTypes = {
  span: PropTypes.number,
};

export default Column
