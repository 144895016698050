import Label from "./Label"
import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"
import "./styles.scss"

const Select = ({
  label,
  labelHidden,
  name,
  placeholder,
  options,
  value,
  onChange,
  valid,
  required,
  ...rest
}) => {
  const getOptions = () => {
    const selectOptions = []

    if (placeholder) {
      selectOptions.push(
        <option
          key="placeholder"
          value=""
          className="form__option--placeholder"
        >
          {placeholder}
        </option>
      )
    }

    if (Array.isArray(options)) {
      selectOptions.push(
        options.map((option, i) => <option key={i}>{option}</option>)
      )
    } else if (typeof options === "object" && options !== null) {
      selectOptions.push(
        Object.keys(options).map((option, i) => (
          <option key={i} value={option}>
            {options[option]}
          </option>
        ))
      )
    }
    return selectOptions
  }

  const getClassNames = () =>
    classNames(
      "form__item",
      "form__item--select",
      `form__item--${name}`,
      !valid && "form__item--invalid"
    )

  const handleChange = event => {
    onChange && onChange(event.target.name, event.target.value)
  }

  return (
    <div className={getClassNames()}>
      <Label label={label} name={name} labelHidden={labelHidden} required />
      <select
        name={name}
        id={name}
        className="form__select"
        value={value}
        onChange={handleChange}
        {...rest}
      >
        {getOptions()}
      </select>
    </div>
  )
}

Select.defaultProps = {
  placeholder: null,
  labelHidden: false,
  onChange: null,
  required: false,
  valid: true,
}

Select.propTypes = {
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  labelHidden: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  valid: PropTypes.bool,
}

export default Select
