import React, { useContext, useState } from "react"
import axios from "axios"
import moment from "moment"
import {
  Step,
  Form,
  TextInput,
  Checkbox,
  Select,
  SchoolLookup,
  Button,
} from "./"
import OutboundLink from "../OutboundLink"
import CoppaContext from "../../context/CoppaContext"

const FormStatus = {
  NOT_STARTED: "NOT_STARTED",
  STARTED: "STARTED",
  SUBMITTED: "SUBMITTED",
  COMPLETE: "COMPLETE",
}

const initialState = {
  formStep: 1,
  formFields: {
    1: {
      firstname: {
        value: "",
        isValid: false,
      },
      lastname: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },
      birthdayDay: {
        value: "",
        isValid: false,
      },
      birthdayMonth: {
        value: "",
        isValid: false,
      },
      birthdayYear: {
        value: "",
        isValid: false,
      },
      zip: {
        value: "",
        isValid: false,
      },
      "Receive email from CATCH": {
        value: true,
        isValid: true,
      },
    },
    2: {
      usageTiming: {
        value: "",
        isValid: false,
      },
      numberReached: {
        value: "",
        isValid: false,
      },
      studentAwarenessImportance: {
        value: "",
        isValid: false,
      },
      communicationEffectiveness: {
        value: "",
        isValid: false,
      },
      school: {
        value: {},
        isValid: false,
      },
    },
  },
  formComplete: false,
}

const COPPA_MIN_AGE = 13

const ParentAccess = ({ id, onSubmit }) => {
  const [formStatus, setFormStatus] = useState(FormStatus.NOT_STARTED)
  const [state, setState] = useState(initialState)
  const coppaContext = useContext(CoppaContext)

  const validateField = (field, value) => {
    let validated = false
    switch (field) {
      case "email":
        validated = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
        break
      case "zip":
        validated = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
        break
      case "school":
        validated = Object.keys(value).every(key => value[key].valid)
        break
      default:
        validated = value !== null && value !== ""
        break
    }

    return validated
  }

  const handleFormChange = (field, value) => {
    if (formStatus === FormStatus.NOT_STARTED) {
      setFormStatus(FormStatus.STARTED)
    }
    setState(state => ({
      ...state,
      formFields: {
        ...state.formFields,
        [state.formStep]: {
          ...state.formFields[state.formStep],
          [field]: {
            value,
            isValid: validateField(field, value),
          },
        },
      },
    }))
  }

  const fieldsAreValid = () => {
    const { formStep, formFields } = state
    const fields = { ...formFields[formStep] }
    const invalidFieldKeys = Object.keys(fields).filter(
      key => !fields[key].isValid
    )
    return invalidFieldKeys.length === 0
  }

  const birthdayIsOkay = () => {
    const { formStep, formFields } = state
    if (formStep === 1) {
      const birthday = moment(
        `${formFields[1].birthdayYear.value}/${formFields[1].birthdayMonth.value}/${formFields[1].birthdayDay.value}`
      )
      const age = moment().diff(birthday, "years")
      const birthdayFieldsFilled =
        formFields[1].birthdayDay.value &&
        formFields[1].birthdayMonth.value &&
        formFields[1].birthdayYear.value
      if (!birthdayFieldsFilled || !birthday.isValid()) {
        return false
      } else if (age < COPPA_MIN_AGE) {
        coppaContext.setCoppaRestricted(true)
        return false
      }
    }
    return true
  }

  const handleFormSubmit = () => {
    if (
      formStatus === FormStatus.NOT_STARTED ||
      formStatus === FormStatus.STARTED
    ) {
      setFormStatus(FormStatus.SUBMITTED)
    }
    const { formStep } = state
    if (birthdayIsOkay() && fieldsAreValid()) {
      switch (formStep) {
        case 1:
          setState(state => ({
            ...state,
            formStep: state.formStep + 1,
          }))
          setFormStatus(FormStatus.STARTED)
          break
        case 2:
          postForm()
          onSubmit()
          break
        default:
          break
      }
    }
  }

  const postForm = () => {
    const { formFields } = state
    const schoolValue = {}
    Object.keys(formFields[2].school.value).forEach(
      key => (schoolValue[key] = formFields[2].school.value[key].value)
    )
    const fields = {
      type: "access",
      email: formFields[1].email.value,
      data: {
        Form: id,
        firstname: formFields[1].firstname.value,
        lastname: formFields[1].lastname.value,
        "Receive email from CATCH":
          formFields[1]["Receive email from CATCH"].value,
        usageTiming: formFields[2].usageTiming.value,
        numberReached: formFields[2].numberReached.value,
        studentAwarenessImportance:
          formFields[2].studentAwarenessImportance.value,
        communicationEffectiveness:
          formFields[2].communicationEffectiveness.value,
        ...schoolValue,
      },
    }
    axios.post(`/api/form-entry`, fields, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    setState({ ...state, formComplete: true })
    setFormStatus(FormStatus.COMPLETE)
  }

  const validationWarningEnabled = () => {
    return formStatus === FormStatus.SUBMITTED
  }

  const { formStep, formFields } = state
  return (
    <Form onSubmit={handleFormSubmit} classes={["parent-access-form"]}>
      <Step current={formStep === 1}>
        <TextInput
          type="text"
          label="First Name"
          name="firstname"
          placeholder="First Name"
          value={formFields[1].firstname.value}
          labelHidden
          valid={
            validationWarningEnabled() ? formFields[1].firstname.isValid : true
          }
          onChange={handleFormChange}
        />
        <TextInput
          type="text"
          label="Last Name"
          name="lastname"
          placeholder="Last Name"
          value={formFields[1].lastname.value}
          labelHidden
          valid={
            validationWarningEnabled() ? formFields[1].lastname.isValid : true
          }
          onChange={handleFormChange}
        />
        <TextInput
          type="email"
          label="Email"
          name="email"
          placeholder="Email"
          value={formFields[1].email.value}
          labelHidden
          valid={
            validationWarningEnabled() ? formFields[1].email.isValid : true
          }
          onChange={handleFormChange}
        />
        <div className="subscribe__birthday-fields">
          <Select
            name="birthdayMonth"
            label="Birthday Month"
            placeholder="Month"
            options={{
              1: "January",
              2: "February",
              3: "March",
              4: "April",
              5: "May",
              6: "June",
              7: "July",
              8: "August",
              9: "September",
              10: "October",
              11: "November",
              12: "December",
            }}
            labelHidden
            valid={
              validationWarningEnabled()
                ? formFields[1].birthdayMonth.isValid
                : true
            }
            onChange={handleFormChange}
          />
          <Select
            name="birthdayDay"
            label="Birthday Day"
            placeholder="Day"
            options={[...Array(31).keys()].map(number => 1 + number)}
            labelHidden
            valid={
              validationWarningEnabled()
                ? formFields[1].birthdayDay.isValid
                : true
            }
            onChange={handleFormChange}
          />
          <Select
            name="birthdayYear"
            label="Birthday Year"
            placeholder="Year"
            options={[...Array(85).keys()].map(
              number => new Date().getFullYear() - number
            )}
            labelHidden
            valid={
              validationWarningEnabled()
                ? formFields[1].birthdayYear.isValid
                : true
            }
            onChange={handleFormChange}
          />
        </div>
        <TextInput
          type="text"
          label="Zip Code"
          name="zip"
          placeholder="Zip Code"
          value={formFields[1].zip.value}
          valid={validationWarningEnabled() ? formFields[1].zip.isValid : true}
          labelHidden
          onChange={handleFormChange}
        />
        <Checkbox
          name="Receive email from CATCH"
          label={
            <span>
              I would like to receive information about the other health
              education topics from CATCH in accordance with the following{" "}
              <OutboundLink to="/resources/BeVapeFree.org_Privacy-Policy_2020.pdf">
                  Privacy Policy
              </OutboundLink>
              .
            </span>
          }
          checked={formFields[1]["Receive email from CATCH"].value}
          labelHidden
          onChange={handleFormChange}
        />
      </Step>
      <Step current={formStep === 2}>
        <Select
          name="usageTiming"
          label="When will you use these resources?"
          placeholder="Select Timeframe"
          options={["Today", "This week", "This month", "Not sure"]}
          value={formFields[2].usageTiming.value}
          valid={
            validationWarningEnabled()
              ? formFields[2].usageTiming.isValid
              : true
          }
          onChange={handleFormChange}
        />
        <Select
          label="How many people will be reached?"
          name="numberReached"
          placeholder="How many people will be reached?"
          options={[
            "10—20 students",
            "20—30 students",
            "30—40 students",
            "40—50 students",
            "50—60 students",
            "60—70 students",
            "70—80 students",
            "80—90 students",
            "90—100 students",
            "100+ students",
          ]}
          value={formFields[2].numberReached.value}
          valid={
            validationWarningEnabled()
              ? formFields[2].numberReached.isValid
              : true
          }
          onChange={handleFormChange}
        />
        <Select
          name="studentAwarenessImportance"
          label="How important do you think it is to teach kids/students about the impact of vaping?"
          placeholder="Please Select An Importance Level"
          options={{
            1: "1 - Not at all important",
            2: "2",
            3: "3",
            4: "4 - Very Important",
          }}
          value={formFields[2].studentAwarenessImportance.value}
          valid={
            validationWarningEnabled()
              ? formFields[2].studentAwarenessImportance.isValid
              : true
          }
          onChange={handleFormChange}
        />
        <Select
          name="communicationEffectiveness"
          label="How confident do you feel in your ability to talk effectively to your kids/students about the impact of vaping?"
          placeholder="Please Select A Confidence Level"
          options={{
            1: "1 - Not very confident",
            2: "2",
            3: "3",
            4: "4 - Very Confident",
          }}
          value={formFields[2].communicationEffectiveness.value}
          valid={
            validationWarningEnabled()
              ? formFields[2].communicationEffectiveness.isValid
              : true
          }
          onChange={handleFormChange}
        />
        <SchoolLookup
          zip={parseInt(formFields[1].zip.value)}
          value={formFields[2].school.value}
          onChange={handleFormChange}
          useOrgLabels={true}
          validationWarningEnabled={validationWarningEnabled()}
        />
      </Step>

      <Button>{formStep === 1 ? "Continue" : "Submit"}</Button>
    </Form>
  )
}

export default ParentAccess
