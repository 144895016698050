import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"
import "./styles.scss"

class Form extends React.Component {
  handleSubmit = event => {
    event.preventDefault()
    const { onSubmit } = this.props
    onSubmit && onSubmit()
  }

  render() {
    const classes = this.props.classes || []
    return (
      <form
        className={classNames("form", ...classes)}
        onSubmit={this.handleSubmit}
      >
        {this.props.children}
      </form>
    )
  }
}

Form.defaultProps = {
  onSubmit: null,
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  classes: PropTypes.array,
}

export default Form
