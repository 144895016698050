import { useEffect, useState } from "react"

const HIGH_KEY = "highSchoolProcessed"
const MIDDLE_KEY = "middleSchoolProcessed"
const ELEM_KEY = "elementarySchoolProcessed"
const FIELDS_KEY = "educatorFields"

/**
 * Context value for the Elementary and Middle School
 * access forms.
 */
const useEducatorContextValue = () => {
  // Educator Context
  const [highSchoolProcessed, setHighSchoolProcessed] = useState(false)
  const [middleSchoolProcessed, setMiddleSchoolProcessed] = useState(false)
  const [elementarySchoolProcessed, setElementarySchoolProcessed] = useState(false)

  const [submittedFields, setSubmittedFields] = useState({})

  const [educatorContextValue, setEducatorContextValue] = useState({
    highSchoolProcessed: false,
    markHighSchoolAsProcessed: () => { },
    middleSchoolProcessed: false,
    markMiddleSchoolAsProcessed: () => { },
    elementarySchoolProcessed: false,
    markElementarySchoolAsProcessed: () => { },
    submittedFields: {},
    setSubmittedFields: () => { },
  })

  // componentDidMount
  useEffect(() => {
    setHighSchoolProcessed(sessionStorage.getItem(HIGH_KEY) === "true")
    setMiddleSchoolProcessed(sessionStorage.getItem(MIDDLE_KEY) === "true")
    setElementarySchoolProcessed(sessionStorage.getItem(ELEM_KEY) === "true")
    setSubmittedFields(
      (sessionStorage.getItem(FIELDS_KEY) &&
        JSON.parse(sessionStorage.getItem(FIELDS_KEY))) ||
      {}
    )
  }, [])

  useEffect(() => {
    setEducatorContextValue({
      highSchoolProcessed,
      markHighSchoolAsProcessed: () => {
        sessionStorage.setItem(MIDDLE_KEY, true)
        setHighSchoolProcessed(true)
      },
      middleSchoolProcessed,
      markMiddleSchoolAsProcessed: () => {
        sessionStorage.setItem(MIDDLE_KEY, true)
        setMiddleSchoolProcessed(true)
      },
      elementarySchoolProcessed,
      markElementarySchoolAsProcessed: () => {
        sessionStorage.setItem(ELEM_KEY, true)
        setElementarySchoolProcessed(true)
      },
      submittedFields,
      setSubmittedFields: fields => {
        sessionStorage.setItem(FIELDS_KEY, JSON.stringify(fields))
        setSubmittedFields(fields)
      },
    })
  }, [highSchoolProcessed, middleSchoolProcessed, elementarySchoolProcessed, submittedFields])

  return educatorContextValue
}

export default useEducatorContextValue
