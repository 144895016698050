/**
 * Return true if field has valid zip code format, else false.
 */
const validateZipCode = value => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)

const validateNonEmptyString = value => {
  return !!value.trim && !!value.trim().length
}

export { validateNonEmptyString, validateZipCode }
